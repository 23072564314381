import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import moment from 'moment-timezone';
import { combineLatest, startWith } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { MatIcon } from '@angular/material/icon';
import { DsDividendsChartComponent } from '@c/dividends-strategy-content/dividends-strategy-dividends-list/ds-dividends-chart/ds-dividends-chart.component';
import { DsDividendsTableComponent } from '@c/dividends-strategy-content/dividends-strategy-dividends-list/ds-dividends-table/ds-dividends-table.component';
import { DividendsStrategyScannerFilterModel } from '@c/dividends-strategy-content/dividends-strategy-scanner-filter/dividends-strategy-scanner-filter.model';
import { EasternTimeZoneName, MomentDateTimeFormats, TabNames } from '@const';
import { DividendsStrategyDividendsResponseModel } from '@mod/dividends-strategy/dividends-strategy.model';
import { DividendsStrategyStateService } from '@s/dividends-strategy/dividends-strategy-state.service';
import { DividendsStrategyService } from '@s/dividends-strategy/dividends-strategy.service';
import { MarketTimeService } from '@s/market-time.service';
import { ObservableService } from '@s/observable.service';
import { convertToEasternTime, isNullOrUndefinedOrEmpty, round } from '@u/utils';
import { DEFAULT_DIVIDENDS_LIST_INTERVAL_MONTHS } from '../dividends-strategy-scanner-filter/dividends-strategy-scanner-filter.data';

@Component({
  selector: 'app-dividends-strategy-dividends-list',
  templateUrl: './dividends-strategy-dividends-list.component.html',
  styleUrl: './dividends-strategy-dividends-list.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, MatTabGroup, MatTab, DsDividendsTableComponent, DsDividendsChartComponent, MatIcon],
})
export class DividendsStrategyDividendsListComponent {
  protected readonly currentSymbol$ = this.observableService.symbol;
  protected readonly activeTab = this.observableService.activeTab.getValue();

  // for table and chart
  protected readonly dividendsDataSource$ = combineLatest([
    this.currentSymbol$.pipe(switchMap((securityId) => this.dividendsStrategyService.getDividends(securityId))),
    this.dividendsStrategyStateService.scannerFilters$,
  ]).pipe(
    map(([performance, filtersState]) => {
      return {
        filteredDividends: this.filterDividends(performance, filtersState),
        filtersState,
      };
    }),
    map(({ filteredDividends, filtersState }) => {
      if (!filteredDividends || !filtersState) {
        return [];
      }

      const filterIntervalMonths = filtersState.breakEvenPeriodYears * 12;
      let maxBreakEvenDaysCount = 0;
      let maxDividendsValue = 0;

      filteredDividends.forEach((item) => {
        if (!isNullOrUndefinedOrEmpty(item.value) && item.value > maxDividendsValue) {
          maxDividendsValue = item.value;
        }

        const trade = item.trades.find((trade) => trade.interval_months === filterIntervalMonths);

        if (trade?.duration_days !== null && trade?.duration_days > maxBreakEvenDaysCount) {
          maxBreakEvenDaysCount = trade?.duration_days ?? null;
        }
      });

      const etNow = moment.tz(EasternTimeZoneName);

      return [...filteredDividends]
        .sort((a, b) => {
          return moment(a.date).isSameOrBefore(moment(b.date), 'date') ? 1 : -1;
        })
        .map((item) => {
          const trade = item.trades.find((trade) => trade.interval_months === filterIntervalMonths);

          const isDividendToday = convertToEasternTime(item.date).isSame(etNow, 'day');
          let dividendsValueProgressBarWidth = 0;
          let breakEvenProgressBarWidth = 0;

          if (maxDividendsValue > 0 && item.value > 0) {
            dividendsValueProgressBarWidth = round((item.value / maxDividendsValue) * 100, 2);
          }

          if (maxBreakEvenDaysCount > 0 && trade?.is_entry && trade?.duration_days > 0) {
            breakEvenProgressBarWidth = round((trade?.duration_days / maxBreakEvenDaysCount) * 100, 2);
          }

          const exDateMoment = convertToEasternTime(item.date);
          const paymentDateMoment = convertToEasternTime(item.payment_date);

          // if no exit_date for trade (based on selected break-even period) - show '-'
          const isParentTriggered =
            !isDividendToday &&
            !isNullOrUndefinedOrEmpty(trade) &&
            !trade.is_entry &&
            !isNullOrUndefinedOrEmpty(trade?.exit_date);

          return {
            id: `${item.date}_${item.payment_date}`,
            exDate: item.date ?? null,
            exDateFormatted: exDateMoment ? exDateMoment.format(MomentDateTimeFormats.ReadableDateFullYear) : '-',
            paymentDate: item.payment_date,
            paymentDateFormatted: paymentDateMoment
              ? paymentDateMoment.format(MomentDateTimeFormats.ReadableDateFullYear)
              : '-',
            dividend: {
              value: item.value,
              progressBarWidth: dividendsValueProgressBarWidth,
            },
            breakEven: {
              value: trade?.is_entry ? trade?.duration_days : null,
              progressBarWidth: breakEvenProgressBarWidth,
            },
            parentTriggered: isParentTriggered,
          };
        });
    }),
    startWith([]),
  );

  @ViewChild('dividendsDataTable') tableElementRef: ElementRef<HTMLTableElement>;

  constructor(
    private observableService: ObservableService,
    private dividendsStrategyStateService: DividendsStrategyStateService,
    private dividendsStrategyService: DividendsStrategyService,
    private changeDetectorRef: ChangeDetectorRef,
    private marketTimeService: MarketTimeService,
  ) {}

  private filterDividends(
    dividends: DividendsStrategyDividendsResponseModel[],
    filters: DividendsStrategyScannerFilterModel,
  ): DividendsStrategyDividendsResponseModel[] {
    if (!dividends || dividends.length === 0) {
      return [];
    }

    const filterIntervalMonths =
      this.activeTab === TabNames.DividendsStrategy
        ? filters.breakEvenPeriodYears * 12
        : DEFAULT_DIVIDENDS_LIST_INTERVAL_MONTHS;

    let dateTo = moment.tz(EasternTimeZoneName).startOf('day');

    if (this.marketTimeService.isAfterCronStart()) {
      dateTo = this.marketTimeService.getNextWorkingDay(1, dateTo);
    }

    let isFirstTradeFound = false;
    const filteredDividends = [];

    for (const dividend of dividends) {
      const exDate = moment(dividend.date).tz(EasternTimeZoneName).startOf('day');

      if (exDate.unix() > dateTo.unix()) {
        break;
      }

      // The very first dividend in the period should always have a trade - this is the starting point
      if (!isFirstTradeFound) {
        isFirstTradeFound = dividend.trades?.some((t) => t.interval_months === filterIntervalMonths);
      }

      if (isFirstTradeFound) {
        filteredDividends.push(dividend);
      }
    }

    return filteredDividends;
  }
}
