<div
  #chartContainer
  class="grouped-chart"
>
  @if (dataSource && dataSource.length > 0) {
    <div class="header">Dividends</div>
    <div class="scroll-data chart-scroll-container">
      <div [ngStyle]="{ 'width.px': chartWidthPx }">
        @if (isActive()) {
          <apx-chart
            [series]="series"
            [chart]="chartOptions.chart"
            [annotations]="isShowZeroLine ? chartOptions.annotations : undefined"
            [legend]="chartOptions.legend"
            [stroke]="chartOptions.stroke"
            [plotOptions]="chartOptions.plotOptions"
            [dataLabels]="chartOptions.dataLabels"
            [grid]="chartOptions.grid"
            [xaxis]="xAxis"
            [yaxis]="yAxis"
            [title]="chartOptions.title"
            [states]="chartOptions.states"
          ></apx-chart>
          <div class="chart-footer">
            <div class="chart-marker blue"></div>
            <span class="name">Paid Dividend</span>
          </div>
        }
      </div>
    </div>
  } @else {
    <div class="no-data-message">No data found.</div>
  }
</div>
