<div
  [class.table-sticky-style]="useTableStickyStyle"
  #dividendsDataTable
  class="dividends-data-table-wrapper scroll-style"
>
  @let currentSymbolPerformance = dividends();

  <table class="table-styles table-main-style dividends-data-table">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th class="text-center">Ex-Date</th>
        <th class="text-center">Payment Date</th>
        <th class="text-center">Dividends</th>
      </tr>
    </thead>

    <tbody>
      @for (item of currentSymbolPerformance; track item.id; let index = $index) {
        <tr class="row">
          <td class="value index text-center">
            {{ index + 1 }}
          </td>

          <td class="value ex-date text-center">
            {{ item.exDateFormatted }}
          </td>

          <td class="value payment-date text-center">
            {{ item.paymentDateFormatted }}
          </td>

          <td class="cell value break-even">
            <ng-container
              [ngTemplateOutlet]="dividendValueProgressBar"
              [ngTemplateOutletContext]="{ dividend: item.dividend }"
            ></ng-container>
          </td>
        </tr>
      }
    </tbody>
  </table>

  @if (currentSymbolPerformance.length === 0) {
    <div class="no-data-message">No data found.</div>
  }
</div>

<ng-template
  #dividendValueProgressBar
  let-dividend="dividend"
>
  @if (dividend && dividend.value !== null) {
    <div class="break-even-progress">
      <div class="value">${{ dividend.value | number: '1.2-4' }}</div>
    </div>
  }
</ng-template>

<ng-template
  #breakEvenProgressBar
  let-breakEven="breakEven"
  let-parentTriggered="parentTriggered"
>
  @if (breakEven && breakEven.value !== null) {
    <div class="break-even-progress">
      <div class="value">{{ breakEven.value | number: '1.0-2' }}d</div>

      <div class="progress">
        <div
          [ngStyle]="{ '--progress-bar-width': breakEven.progressBarWidth + '%' }"
          class="line"
        ></div>
      </div>
    </div>
  } @else if (parentTriggered) {
    <ng-container *ngTemplateOutlet="breakEvenParentTriggered"></ng-container>
  } @else {
    <ng-container *ngTemplateOutlet="breakEvenEmptyValue"></ng-container>
  }
</ng-template>

<ng-template #breakEvenParentTriggered>
  <div class="break-even-progress empty-value">
    <div class="value not-yet">Parent-triggered</div>
  </div>
</ng-template>

<ng-template #breakEvenEmptyValue>
  <div class="break-even-progress empty-value">
    <div class="value not-yet">-</div>
  </div>
</ng-template>
