import { DecimalPipe, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  Injector,
  input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DividendsTableItemModel } from '@c/dividends-strategy-content/dividends-strategy-dividends-list/ds-dividends-table/ds-dividends-table.model';

@Component({
  selector: 'app-ds-dividends-table',
  templateUrl: './ds-dividends-table.component.html',
  styleUrl: './ds-dividends-table.component.scss',
  standalone: true,
  imports: [DecimalPipe, NgStyle, NgTemplateOutlet, MatTooltip],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DsDividendsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  public dividends = input.required<DividendsTableItemModel[]>();
  public isActive = input.required<boolean>();

  protected useTableStickyStyle = true;

  private subscriptions = new Subscription();

  @ViewChild('dividendsDataTable') tableElementRef: ElementRef<HTMLTableElement>;

  constructor(
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    effect(
      () => {
        const isActive = this.isActive();

        if (!isActive) {
          return;
        }

        this.updateTableSize();
      },
      { injector: this.injector },
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(500))
        .subscribe(() => {
          this.updateTableSize();
        }),
    );

    this.updateTableSize();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateTableSize(): void {
    const table = this.tableElementRef?.nativeElement;

    if (!table) {
      return;
    }

    this.useTableStickyStyle = table.clientWidth !== table.scrollWidth;
    this.changeDetectorRef.markForCheck();
  }
}
