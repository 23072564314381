import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';

import { StorageKeys } from '@const';
import { Response } from '@core1/interface';
import {
  DividendsStrategyDetailsResponseModel,
  DividendsStrategyDividendsResponseModel,
  DividendsStrategyScannerResponseModel,
} from '@mod/dividends-strategy/dividends-strategy.model';
import { RestRequestorService } from '@s/rest-requestor.service';
import { isNullOrUndefinedOrEmpty } from '@u/utils';

@Injectable({
  providedIn: 'root',
})
export class DividendsStrategyService {
  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService,
  ) {}

  public runScanner = async (): Promise<DividendsStrategyScannerResponseModel[]> => {
    const response = await firstValueFrom(
      this.http.get<Response<DividendsStrategyScannerResponseModel[]>>(`/v2/dividendsStrategy/run`),
    );

    return response.result;
  };

  public getPastPerformanceDetails = async (securityId: number): Promise<DividendsStrategyDetailsResponseModel[]> => {
    if (isNullOrUndefinedOrEmpty(securityId)) {
      return [];
    }

    const date = moment().utc().format('YYYY-MM-DD');
    const storageKey = `${StorageKeys.DividendsStrategySymbolDetails}_${securityId}_${date}`;

    const { result } = await this.restRequesterService.makeRequest(storageKey, () =>
      firstValueFrom(this.http.get<Response>(`/v2/dividendsStrategy/details/${securityId}`)),
    );

    return result;
  };

  public getDividends = async (securityId: number): Promise<DividendsStrategyDividendsResponseModel[]> => {
    const date = moment().utc().format('YYYY-MM-DD');
    const storageKey = `${StorageKeys.DividendsStrategySymbolDividends}_${securityId}_${date}`;

    const { result } = await this.restRequesterService.makeRequest(storageKey, () =>
      firstValueFrom(this.http.get<Response>(`/v2/dividendsStrategy/dividends/${securityId}`)),
    );

    return result;
  };
}
