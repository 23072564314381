import { NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  Injector,
  input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ApexAxisChartSeries, ApexOptions, ApexXAxis, ApexYAxis } from 'ng-apexcharts/lib/model/apex-types';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import {
  ChartDataSourceItemModel,
  DividendsChartItemModel,
} from '@c/dividends-strategy-content/dividends-strategy-dividends-list/ds-dividends-chart/ds-dividends-chart.model';
import { MomentDateTimeFormats } from '@const';
import { convertToEasternTime, formatDecimalValue } from '@u/utils';

@Component({
  selector: 'app-ds-dividends-chart',
  templateUrl: './ds-dividends-chart.component.html',
  styleUrl: './ds-dividends-chart.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ChartComponent, NgStyle],
})
export class DsDividendsChartComponent implements OnInit, OnDestroy, AfterViewInit {
  public dividends = input.required<DividendsChartItemModel[]>();
  public isActive = input.required<boolean>();

  @ViewChild('chartContainer') protected component: ElementRef;

  protected chartHeightPx = 280;
  protected minBarWidthPx = 32;
  protected pageContainerPaddingsPx = 0;
  protected chartHorizontalPaddingsPx = 150;

  protected chartWidthPx = window.innerWidth - this.pageContainerPaddingsPx;
  protected dataSource: ChartDataSourceItemModel[] = [];
  protected isShowZeroLine = false;

  protected initialPaidDividendSeriesSettings = {
    name: '',
    data: [],
    color: 'var(--paid-dividend-bar-style-color)',
  };

  protected series: ApexAxisChartSeries = [{ ...this.initialPaidDividendSeriesSettings, data: [] }];

  protected xAxis: ApexXAxis = {
    categories: [],
    labels: {
      show: true,
      showDuplicates: true,
      hideOverlappingLabels: false,
      style: {
        fontSize: '10px',
        colors: 'var(--main-font-color)',
        cssClass: 'x-axis-label',
      },
    },
  };

  protected yAxis: ApexYAxis = {
    decimalsInFloat: 2,
    labels: {
      style: {
        fontSize: '10px',
        colors: 'var(--main-font-color)',
      },
      formatter: (value) => {
        return formatDecimalValue(value, 2, 4);
      },
    },
  };

  protected chartOptions: ApexOptions = {
    chart: {
      id: 'dividends-chart',
      height: this.chartHeightPx,
      events: {
        // there are no types for this event in ApexCharts, temporarily disabled
        // mounted: (chartContext, config) => {
        //   // Dynamically adjust rotation after chart is mounted
        //   this.updateLabelRotation(chartContext, config);
        // },
      },
      type: 'bar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 200,
        animateGradually: {
          enabled: true,
          delay: 50,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 200,
        },
      },
      zoom: {
        enabled: false,
        type: 'x',
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          offsetY: 0.5,
          strokeDashArray: 0,
          borderWidth: 1,
          fillColor: '#AFAFC0',
          borderColor: '#AFAFC0',
        },
      ],
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '14px',
      itemMargin: {
        vertical: 10,
        horizontal: 2,
      },
      labels: {
        colors: 'var(--main-font-color)',
      },
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent'],
      lineCap: 'round',
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
        borderRadius: 2,
        dataLabels: {
          maxItems: 48,
          position: 'top',
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0,
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetY: -20,
    },
    grid: {
      show: true,
      borderColor: 'var(--table-border-color)',
      strokeDashArray: 0,
      position: 'back',
    },
  };

  private subscriptions = new Subscription();

  constructor(
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    effect(
      () => {
        const dividends = this.dividends();
        const isActive = this.isActive();

        if (!isActive) {
          return;
        }

        this.updateChart(dividends);

        const seriesNumber = this.series[0].data.length;
        const width = this.component.nativeElement.offsetWidth;

        this.updateChartSize(width, seriesNumber);
      },
      { injector: this.injector },
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      fromEvent(window, 'resize')
        .pipe(
          debounceTime(500),
          map(() => this.component.nativeElement.offsetWidth),
          distinctUntilChanged(),
        )
        .subscribe((width) => {
          const seriesNumber = this.series[0].data.length;
          this.updateChartSize(width, seriesNumber);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // there are no types for this event in ApexCharts
  protected updateLabelRotation(chart, options): void {
    try {
      const gridWidth = chart?.w?.globals?.gridWidth;
      const totalXLabels = options?.config?.xaxis?.categories?.length;

      if (!gridWidth || !totalXLabels) {
        return;
      }

      const spacePerLabel = gridWidth / totalXLabels;
      const minSpaceWidthForLabelPx = 80;

      options.config.xaxis.labels.rotateAlways = spacePerLabel < minSpaceWidthForLabelPx;
      chart.updateOptions(options);
    } catch (error) {
      console.error('DS, Dividends chart | Error while updating label rotation', error);
    }
  }

  private updateChartSize(width: number, seriesNumber: number): void {
    const minChartWidth = this.minBarWidthPx * seriesNumber + this.chartHorizontalPaddingsPx;

    this.chartWidthPx = Math.max(width - this.pageContainerPaddingsPx, minChartWidth);
    this.chartOptions.chart = { ...this.chartOptions.chart, width: this.chartWidthPx };

    this.changeDetectorRef.markForCheck();
  }

  private updateChart(dividends: DividendsChartItemModel[]): void {
    this.dataSource = this.transformDividendsToChartDataSource(dividends);

    const xAxis = this.dataSource.map((details) => details.xAxisLabel);

    this.xAxis = {
      ...this.xAxis,
      range: xAxis.length - 1,
      categories: xAxis,
    };

    this.isShowZeroLine = this.dataSource.some((item) => item.paidDividend < 0);

    // or calculate from min absolute value (not 0 or null) divided by 10 to make it visible in all cases
    const zeroBarValue = 0.0005;

    this.series = [
      {
        ...this.initialPaidDividendSeriesSettings,
        data: this.dataSource.map((details) => {
          if (details.paidDividend === null) {
            return null;
          }

          return details.paidDividend || zeroBarValue;
        }),
      },
    ];

    this.changeDetectorRef.markForCheck();
  }

  private transformDividendsToChartDataSource(dividends: DividendsChartItemModel[]): ChartDataSourceItemModel[] {
    return [...dividends].map((details) => ({
      paymentDate: details.paymentDate,
      xAxisLabel: this.getXAxisLabel(details.paymentDate),
      paidDividend: details.dividend.value,
    }));
  }

  // Format:  <MMM> <D>`<YY>, example: Aug 1’22.
  // Where: <MMM>: month, short; <D>: date; <YY>: Last 2 digits of the year.
  private getXAxisLabel(date: string | null): string {
    const dateMoment = convertToEasternTime(date);

    if (!dateMoment || !dateMoment.isValid()) {
      return '-';
    }

    const formattedDateMonth = dateMoment.clone().format(MomentDateTimeFormats.ReadableNoYearDate);
    const formattedYear = dateMoment.clone().format(MomentDateTimeFormats.ShortYear);

    return `${formattedDateMonth}’${formattedYear}`;
  }
}
