<div class="dividends-strategy-dividends-list">
  @let dividendsDataSource = dividendsDataSource$ | async;
  @if (dividendsDataSource.length > 1) {
    <div class="pxo-tab-group pxo-tab-group-default">
      <app-ds-dividends-chart
        [isActive]="true"
        [dividends]="dividendsDataSource"
      ></app-ds-dividends-chart>
      <div class="dividends-table">
        <h3 class="dividends-table-header">Payout History</h3>
        <app-ds-dividends-table
          [isActive]="true"
          [dividends]="dividendsDataSource"
        ></app-ds-dividends-table>
      </div>
    </div>
  } @else {
    <div class="no-data">
      <div class="no-data-img"></div>
      <h4 class="modal-header">No data found</h4>
    </div>
  }
</div>
